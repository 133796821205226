:root {
  --dark-blue: #0a73af;
  --blue-parts: 37, 160, 231;
  --blue: rgb(var(--blue-parts));
  --orange: orange;
  --gray-parts: 54, 55, 56;
  --gray: rgb(var(--gray-parts));
  --gray2: #474849;
  --red-parts: 201, 62, 48;
  --red: rgb(var(--red-parts));
  --dark-red: rgb(165, 50, 40);
  --black: var(--gray);
  --green: #76AC47;
  --dark-green: rgb(91, 134, 53);
  --white: white;
  --light-gray: #a7a7a7;

  --bg: rgb(32, 32, 32);
  --text-color: white;
  --wheel: #A47541;
  --error: var(--red);
  --success: var(--green);
  --scroll-color: var(--gray2);

  --scroll-width: 8px;
  --radius: 4px;
  --padding: 16px;
}

body {
  color: white;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica,Arial,sans-serif;
  /* font-size: 12px; */
}

a, a:visited {
  color: var(--text-color);
}

.text-center {
  text-align: center;
}

/*

@function box-shadow($color) {
  @return 0 3px 0px 0px $color;
}
$BOX_SHADOW: box-shadow( rgba($GRAY, 0.7) );
$BOX_SHADOW_RED: box-shadow( rgba($RED, 0.7) );
$BOX_SHADOW_BLUE: box-shadow( rgba($BLUE, 0.7) );

@mixin card {
  background: $GRAY2;
  border-radius: $RADIUS;
  padding: $PADDING;
  box-shadow: $BOX_SHADOW;
  flex: 1;

  h3 {
    margin-bottom: 10px;
    text-align: center;
  }
}

@mixin alert {
  padding: 12px 14px;
  border-radius: $RADIUS;
  margin: 20px 0;
  overflow: hidden;
}

.error-alert {
  @include alert;

  background: $RED;
  box-shadow: $BOX_SHADOW_RED;
}

.info-alert {
  @include alert;

  background: $BLUE;
  box-shadow: $BOX_SHADOW_BLUE;
} */
